import { graphql } from 'gatsby'
import React from 'react'
import HcpLayout from "../components/layout";
import FluidContent from '../components/fluidContent';
import { Seo } from '../components/seo';

function BlogPosts(props) {

  let pageData = props.data.news.nodes[0]

  return (
    <HcpLayout pageClass="news-single-page">
      <FluidContent className='p-60'>
        <div className="centered-content">
          <div className="content-wrap">
            <div class="block-title text-center">
              <h1>{pageData.title}</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: pageData.content }}></div>
          </div>
        </div>
      </FluidContent>
    </HcpLayout>
  )
}

export default BlogPosts;

export const Head = (props) => {

  let pageData = props.data.news.nodes[0]

  return (
    <Seo
      title={pageData.title}
      description=""
    />
  )
};

export const query = graphql`
  query NewsTemplateQuery($slug:String!){
    news:allNewsData(filter: {slug: {eq:$slug }}) {
    nodes {
      author
      title
      date
      content
    }
  }
  }
`